import {
  GET_EKSPDISI_ERROR,
  GET_EKSPDISI_LOADING,
  GET_EKSPDISI_SUCCESS,
  CLEAR_EKSPDISI,
} from "../types/ekspedisi";

const initalState = {
  page: 1,
  total_page: 1,
  data: [],
  total_data: 1,
  loading: false,
  error: false,
};

const expeditionState = (state = initalState, { type, payload }) => {
  switch (type) {
    case GET_EKSPDISI_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_EKSPDISI_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...payload,
      };
    case GET_EKSPDISI_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CLEAR_EKSPDISI:
      return {
        page: 1,
        total_page: 1,
        data: [],
        total_data: 1,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default expeditionState;
