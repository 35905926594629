import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken, getUserRole } from 'src/helpers/cookies';
import { CFade } from '@coreui/react';

export const PrivateRoute = ({ component: Component, role, ...rest }) => {
    return (
        role ?
            <Route {...rest} render={props => (
                getAuthToken() ?
                    getUserRole() === 'owner' ?
                        <CFade>
                            <Component {...props} />
                        </CFade>
                        :
                        <Redirect to="/404" />
                    : <Redirect to="/login" />
            )} />
            :
            <Route {...rest} render={props => (
                getAuthToken() ?
                    (getUserRole() === 'owner' || getUserRole() === 'admin') ?
                        <CFade>
                            <Component {...props} />
                        </CFade>
                        :
                        <Redirect to="/404" />
                    : <Redirect to="/login" />
            )} />
    );
};



