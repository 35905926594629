export const ADD_ITEM_TRANSACTION = 'ADD_ITEM_TRANSACTION';
export const REMOVE_ITEM_TRANSACTION = 'REMOVE_ITEM_TRANSACTION';
export const EDIT_ITEM_TRANSACTION = 'EDIT_ITEM_TRANSACTION';
export const ADD_CUSTOMER_TRANSACTION = 'ADD_CUSTOMER_TRANSACTION';
export const REMOVE_CUSTOMER_TRANSACTION = 'REMOVE_CUSTOMER_TRANSACTION';
export const ADD_GUEST_TRANSACTION  = 'ADD_GUEST_TRANSACTION';
export const RESET_TRANSACTION = 'RESET_TRANSACTION';
export const SET_EDIT_DATA_TRANSACTION = 'SET_EDIT_DATA_TRANSACTION';

export const ADD_ITEM_TRANSACTION_EDIT = 'ADD_ITEM_TRANSACTION_EDIT';
export const REMOVE_ITEM_TRANSACTION_EDIT = 'REMOVE_ITEM_TRANSACTION_EDIT';
export const EDIT_ITEM_TRANSACTION_EDIT = 'EDIT_ITEM_TRANSACTION_EDIT';
export const ADD_CUSTOMER_TRANSACTION_EDIT = 'ADD_CUSTOMER_TRANSACTION_EDIT';
export const REMOVE_CUSTOMER_TRANSACTION_EDIT = 'REMOVE_CUSTOMER_TRANSACTION_EDIT';
export const ADD_GUEST_TRANSACTION_EDIT  = 'ADD_GUEST_TRANSACTION_EDIT';
export const RESET_TRANSACTION_EDIT = 'RESET_TRANSACTION_EDIT';
export const SET_EDIT_DATA_TRANSACTION_EDIT = 'SET_EDIT_DATA_TRANSACTION_EDIT';