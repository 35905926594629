const {
  ADD_CUSTOMER_TRANSACTION_EDIT,
  ADD_GUEST_TRANSACTION_EDIT,
  ADD_ITEM_TRANSACTION_EDIT,
  EDIT_ITEM_TRANSACTION_EDIT,
  REMOVE_CUSTOMER_TRANSACTION_EDIT,
  REMOVE_ITEM_TRANSACTION_EDIT,
  RESET_TRANSACTION_EDIT,
  SET_EDIT_DATA_TRANSACTION_EDIT,
} = require("../types/transaction");

const initialState = {
  items: [],
  subtotal: 0,
  cashback: 0,
  customer: null,
  guest: null,
  isGuest: true,
  isEdit: false,
  id: null,
};

const editTransactionState = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ITEM_TRANSACTION_EDIT:
      return {
        ...state,
        items: [...state.items, payload],
        subtotal: state.subtotal + payload.subtotal,
        cashback: state.cashback + payload.cashback,
      };
    case REMOVE_ITEM_TRANSACTION_EDIT:
      console.log("state.items");
      console.log(state.items);
      // id_transaction_item
      let type_delete = 0;
      let minus_item = 0;
      let new_items = [];
      state.items.map((item) => {
        if (payload.waybill === item.waybill) {
          if (item.id_transaction_item && item.id_transaction_item !== "") {
            if (item.type && item.type === "delete") {
            } else {
              type_delete = 1;
              item.type = "delete";
            }
            new_items.push(item);
          } else {
            minus_item = 1;
          }
        } else {
          new_items.push(item);
        }
        return null;
      });
      let subtotals = state.subtotal;
      let cashbacks = state.cashback;
      if (type_delete) {
        subtotals = state.subtotal - payload.subtotal;
        cashbacks = state.cashback - payload.cashback;
      }
      if (minus_item) {
        cashbacks = state.cashback - payload.cashback;
        subtotals = state.subtotal - payload.subtotal;
      }

      return {
        ...state,
        items: new_items,
        subtotal: subtotals,
        cashback: cashbacks,
      };
    case EDIT_ITEM_TRANSACTION_EDIT:
      const oldItem = state.items.filter(
        (item) => item.waybill === payload.waybill
      );
      let oldSubtotal = 0;
      let oldCashback = 0;
      console.log("cashback " + state.cashback);
      if (oldItem.length > 0) {
        oldSubtotal = oldItem[0].subtotal;
        oldCashback = oldItem[0].cashback;
      }
      console.log(oldCashback);
      const items = state.items.filter(
        (item) => item.waybill !== payload.waybill
      );
      let updateState = state;
      updateState.items = [payload, ...items];
      updateState.subtotal = state.subtotal - oldSubtotal + payload.subtotal;
      updateState.cashback = state.cashback - oldCashback + payload.cashback;
      console.log(updateState);
      return updateState;

    case ADD_CUSTOMER_TRANSACTION_EDIT:
      return {
        ...state,
        isGuest: false,
        customer: payload,
      };
    case REMOVE_CUSTOMER_TRANSACTION_EDIT:
      return {
        ...state,
        isGuest: true,
        customer: null,
      };
    case ADD_GUEST_TRANSACTION_EDIT:
      return {
        ...state,
        isGuest: true,
        customer: null,
        guest: payload,
      };

    case RESET_TRANSACTION_EDIT:
      let new_itemss = [];
      state.items.map((item) => {
        if (item.id_transaction_item && item.id_transaction_item !== "") {
          if (item.type && item.type === "delete") {
          } else {
            item.type = "delete";
          }
          new_itemss.push(item);
        }
        return null;
      });

      let updateStates = state;
      updateStates.items = new_itemss;
      updateStates.subtotal = 0;
      updateStates.cashback = 0;
      console.log(updateStates);
      return updateStates;

    case SET_EDIT_DATA_TRANSACTION_EDIT:
      return {
        ...state,
        ...payload,
        isEdit: true,
      };

    default:
      return state;
  }
};

export default editTransactionState;
