import {
  GET_SERVICE_ERROR,
  GET_SERVICE_LOADING,
  GET_SERVICE_SUCCESS,
  CLEAR_SERVICE,
} from "../types/service";

const initalState = {
  page: 1,
  total_page: 1,
  data: [],
  total_data: 1,
  loading: false,
  error: false,
};

const serviceState = (state = initalState, { type, payload }) => {
  switch (type) {
    case GET_SERVICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...payload,
      };
    case GET_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CLEAR_SERVICE:
      return {
        page: 1,
        total_page: 1,
        data: [],
        total_data: 1,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default serviceState;
