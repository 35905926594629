const profile = null

const getProfile = (state = profile, { type, ...rest }) => {
    switch (type) {
        case 'GET_PROFILE':
            return { ...state, ...rest }
        default:
            return state
    }
}

export default getProfile 