const {
  GET_CITY_LOADING,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
} = require("../types/address");

const initalState = {
  loading: false,
  data: [],
  error: false,
};

const cityState = (state = initalState, { type, payload }) => {
  switch (type) {
    case GET_CITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };
    case GET_CITY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default cityState;
