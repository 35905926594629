import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { PrivateRoute } from 'src/helpers/permissionRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

// transactions
const NewTransaction = React.lazy(() => import('./views/newTransaction/NewTransaction'));
const SuccessPage = React.lazy(() => import('./views/success/SuccessPage'));
const Resetpassword = React.lazy(() => import('./views/pages/login/Reset-password'));
const EditTransaction = React.lazy(() => import('./views/editTransaction'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            {/* <Route exact path="/transaksi/tambah" name="Transaksi Baru" render={props => <NewTransaction {...props} />} /> */}
            <PrivateRoute

              component={NewTransaction}
              path={`/transaksi/tambah`}
              name={`Transaksi Baru`}
              exact={true}
            />
            <PrivateRoute
              component={EditTransaction}
              path={`/transaksi/edit`}
              name={`Edit Transaksi`}
              exact={true}
            />
            {/* <Route exact path="/transaksi/success" name="Transaksi Sukes" render={props => <SuccessPage {...props} />} /> */}
            <PrivateRoute
              component={SuccessPage}
              path={`/transaksi/success`}
              name={`Transaksi Sukes`}
              exact={true}
            />
             <Route
              component={Resetpassword}
              path={`/reset-password`}
              name={`Reset Password`}
              exact={true}
            />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
