const {
  ADD_ITEM_TRANSACTION,
  ADD_CUSTOMER_TRANSACTION,
  REMOVE_CUSTOMER_TRANSACTION,
  ADD_GUEST_TRANSACTION,
  REMOVE_ITEM_TRANSACTION,
  EDIT_ITEM_TRANSACTION,
  RESET_TRANSACTION,
  SET_EDIT_DATA_TRANSACTION,
} = require("../types/transaction");

const initialState = {
  items: [],
  subtotal: 0,
  cashback: 0,
  customer: null,
  guest: null,
  isGuest: true,
  isEdit: false,
  id: null,
};

const transactionState = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ITEM_TRANSACTION:
      return {
        ...state,
        items: [...state.items, payload],
        subtotal: state.subtotal + payload.subtotal,
        cashback: state.cashback + payload.cashback,
      };
    case REMOVE_ITEM_TRANSACTION:
      return {
        ...state,
        items: state.items.filter((item) => item.waybill !== payload.waybill),
        subtotal: state.subtotal - payload.subtotal,
        cashback: state.cashback - payload.cashback,
      };
    case EDIT_ITEM_TRANSACTION:
      const oldItem = state.items.filter(
        (item) => item.waybill === payload.waybill
      );
      let oldSubtotal = 0;
      let oldCashback = 0;
      if (oldItem.length > 0) {
        oldSubtotal = state.subtotal - oldItem[0].subtotal;
        oldCashback = state.cashback - oldItem[0].cashback;
      }
      const items = state.items.filter(
        (item) => item.waybill !== payload.waybill
      );
      let updateState = state;
      updateState.items = [payload, ...items];
      updateState.subtotal = oldSubtotal + payload.subtotal;
      updateState.cashback = oldCashback + payload.cashback;
      return updateState;

    case ADD_CUSTOMER_TRANSACTION:
      return {
        ...state,
        isGuest: false,
        customer: payload,
      };
    case REMOVE_CUSTOMER_TRANSACTION:
      return {
        ...state,
        isGuest: true,
        customer: null,
      };
    case ADD_GUEST_TRANSACTION:
      return {
        ...state,
        isGuest: true,
        customer: null,
        guest: payload,
      };

    case RESET_TRANSACTION:
      return initialState;

    case SET_EDIT_DATA_TRANSACTION:
      return {
        ...state,
        ...payload,
        isEdit: true,
      };

    default:
      return state;
  }
};

export default transactionState;
