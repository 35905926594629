import { nameAuth, userRole } from '../constan/cookies';
import jsCookies from 'js-cookie';

export const deleteAuth = () => {
    return jsCookies.remove(nameAuth, userRole);
}

export const getAuthToken = () => {
    return jsCookies.get(nameAuth);
}

export const getUserRole = () => {
    return jsCookies.get(userRole)
}

