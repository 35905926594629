import { combineReducers } from 'redux'
import ChangeState from './sidebar'
import getProfile from './profile'
import ekspedisi from './ekspedisi';
import service from './service';
import city from './city';
import transaction from './transaction';
import editTransaction from './editTrasaction';

const reducers = combineReducers({
    ChangeState,
    getProfile,
    ekspedisi,
    service,
    city,
    transaction,
    editTransaction,
})

export default reducers 